import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import {Card, CardImg, CardText, CardBody, CardTitle, CardSubtitle, Button} from 'reactstrap';
import { Link } from "react-router-dom";
import { AtomSpinner } from 'react-epic-spinners';

export class ListingsItem extends Component {
    state = {
        imgUrl: '',
        listing_cities: '',
        villa: '',
        fantastic_id: "",
        isLoaded: false
    }
    static propTypes = {
        listing: PropTypes.object.isRequired
    }
    componentDidMount() {
        const { featured_media} = this.props.listing;
        const { listing_cities} = this.props.listing;
        const getImageUrl = axios.get(process.env.REACT_APP_RENTAL_WEBSITE+`/wp-json/wp/v2/attachment/${featured_media}`);
        const getCity = axios.get(process.env.REACT_APP_RENTAL_WEBSITE+`/wp-json/wp/v2/listing_cities/${listing_cities}`);

        Promise.all([getImageUrl, getCity]).then(res => {
            //console.log(res);
            this.setState({
                imgUrl: res[0].data.media_details.sizes.full.source_url,
                listing_cities: res[1].data.name,
                isLoaded: true
            });
        });
    }

    render() {
        const { title, excerpt, id} = this.props.listing;
        const fs_id = this.props.listing.acf.fantastic_id;
        const { imgUrl, listing_cities, isLoaded} = this.state;
        const cleanText = excerpt.rendered.replace(/<\/?[^>]+(>|$)/g, "");
        //Check for title in villa desc & replace it
        var indexOfFirst = 0;
        if (title.rendered.indexOf("&") > -1) {
            indexOfFirst = title.rendered.indexOf("&");
        } else if (title.rendered.indexOf("by") > -1) {
            indexOfFirst = title.rendered.indexOf("by");
        } else {
            indexOfFirst = title.rendered.indexOf("-");
        }
        const final_excerpt = String(cleanText).replaceAll(title.rendered.slice(0, indexOfFirst), 'Villa ');

        /* Debugging villa Names & Desc
        if(title.rendered.includes("Bijoux")){
            console.log( title.rendered.slice(0,indexOfFirst)+" " + indexOfFirst);
            console.log(final_excerpt);
        }*/

        if (isLoaded) {
            return ( 
            <div className = "col-lg-4 float-left mb-5" >
                <Card >
                    <CardImg top width = "100%" src = { imgUrl }  alt = {title.rendered } /> 
                    <CardBody> 
                        <CardTitle className = "visually-hidden" tag = "h5" > {title.rendered } </CardTitle> 
                        <CardTitle tag = "h5" > Villa { id } </CardTitle> 
                        <CardSubtitle tag = "h6" className = "mb-2 text-muted" > {listing_cities } </CardSubtitle> 
                        <CardText> {final_excerpt } </CardText> 
                        <Link className = "mb-2" to = {
                            {pathname: `/villa/${id}`,
                                state: {
                                    villa: id,
                                    villa_name: title.rendered,
                                    fantastic_id: fs_id
                                }
                            } } >
                            <Button className = "mb-2" key = {id}> View Villa </Button>
                        </Link> 
                        < CardSubtitle tag = "h6" className = "mb-2 text-muted text-align-right" > ID: {id} </CardSubtitle>
                    </CardBody> 
                </Card>  
                </div>
            )
        } else {
            return ( 
            <div className = "col-lg-4 float-left mb-5" >
                <Card >
                    <CardBody >
                        <AtomSpinner color = "primary" / >
                    </CardBody> 
                </Card>  
            </div>
            )
        }

    }
}
export default ListingsItem
import Listings from './Listings';
import BackgroundHero from '../Components/BackgroundHero';
import Properties from './Properties';
import {Helmet} from 'react-helmet';


function Home() {
  

  return (
    <div>
      <Helmet>
        <title> Villas in Greece</title>
        <meta name="keywords" 
          content="villas, villas in greece, properties in greece, luxury properties in greece, luxury villas in greece"/>
        <meta name="description" content="Find beautiful luxury villas in Greece. Perfect places for your vacations."
        />
      </Helmet>
      <div className="App">
        <div className="main">
          <BackgroundHero key="HomepageImage"/>
          <h2>Villas to Rent</h2>
          <Listings/> 
        </div>
      </div>
    </div>
  );
}

export default Home;

import React , { useState, useEffect, useRef } from 'react';
import { MapContainer, TileLayer, Marker, Popup} from 'react-leaflet';
import L from 'leaflet';
import "leaflet/dist/leaflet.css";

function LocationMap(props){    
    
    var location = { lat:37.267496, lng: 25.321899 };
    const [villa] = useState();
    var data_loaded = false;

    if( props.position ) {
        location = props.position;
        data_loaded = true;
    }
    //console.log(location); 

    const myIcon = L.divIcon({
        className: 'my-div-icon',
        iconSize: [41, 51], // size of the icon
        popupAnchor: [0, -51], 
        iconAnchor:   [ 20, 51],  // point of the icon which will correspond to marker's location
        html: '<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 384 512" height="3em" width="3em" xmlns="http://www.w3.org/2000/svg"><path d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z"></path></svg>'
    });

    return (
        <div>
        { data_loaded ? 
            <div id="mapid">
                <MapContainer center={[ location.lat || 37.267496, location.lng || 25.321899 ]} zoom={14} scrollWheelZoom={false}>
                    <TileLayer
                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    <Marker icon={myIcon} position={[location.lat || 37.267496 , location.lng || 25.321899]}>
                        <Popup>
                        <span className="center text-center">Location of villa.</span><br/> <br/> 
                        <span className="hidden"></span>
                        <a href={"https://www.google.com/maps/place/"+location.lat+"+"+location.lng} className="btn button">Get Directions</a>
                        </Popup>
                    </Marker>
                </MapContainer>
            </div>
            : 
            <div>
                <span>Location is loading</span>
            </div>
        }
        </div>
    );



}

export default LocationMap;
import React, { useState } from 'react';
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption
} from 'reactstrap';
import { AtomSpinner } from 'react-epic-spinners';

const VillaCarousel = ({items}) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false); 
  //console.log(items);


  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  }

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  }

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  }

  const slides = items.map((item) => {
    //console.log(item);
    return (
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={item.source_url}>
        <img className="d-block w-100" src={item.source_url} alt={item.alt_text} />
        <CarouselCaption className="visually-hidden" captionText={item.alt_text} captionHeader={item.title.rendered} />
      </CarouselItem>
    );
  });


  const images = [];

  if((Object.keys(items.pop)) ){
    for (let i = 0; i < items.length; i++) {
      images.push({
            altText: items[i].alt_text,
            caption: items[i].caption.rendered,
            key: items[i].caption,
            title: items[i].title.rendered,
            source_url: items[i].source_url
        });
    }
    //console.log("images loaded");
    return (
      <Carousel activeIndex={activeIndex} next={next} previous={previous} >
        {slides}
        <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
        <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
        <CarouselIndicators items={images} activeIndex={activeIndex} onClickHandler={goToIndex} />
      </Carousel>
    );
  }else{
    console.log("no images loaded");
    return (
      <div className="container">
        <div className="row text-center">
            <div><AtomSpinner color="primary"/></div>
        </div>
    </div>
    );
  }
}

export default VillaCarousel;
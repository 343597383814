import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import { Col , Row, Container } from 'reactstrap';
import { BiHomeAlt } from "react-icons/bi";
import axios from 'axios';
/* Images Modules */
import VillaCarousel from './VillaCarousel';
import 'photoswipe/dist/photoswipe.css';
import 'photoswipe/dist/default-skin/default-skin.css';
import { Gallery, Item } from 'react-photoswipe-gallery';
/* Map module */
import LocationMap from '../Components/LocationMap';
import "leaflet/dist/leaflet.css";

class SellingVillaView extends Component {

    constructor(props) {
        super(props);

        var today = new Date(),
        date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();

        this.state = {
            property: null,
            opalestate_ppt_sku: null,
            villa: null,
            villa_name: [],
            villa_desc: [],
            featured_media: null,
            opalestate_ppt_areasize: null,
            opalestate_ppt_house_size: null,
            opalestate_ppt_renovation_year: null,
            opalestate_ppt_bedrooms: null,
            opalestate_ppt_bathrooms: null,
            opalestate_ppt_livingrooms: null,
            opalestate_ppt_kitchens: null,
            opalestate_city: null,
            opalestate_location: null,
            opalestate_state: null,
            amenities: [],
            opalestate_amenities: [],
            property_category: null,
            opalestate_types: [],
            imgUrl: null,
            images: [],
            opalestate_ppt_map: [],
            isLoaded: false,
            currentDate: date,
            calendar: [],
            sales_token: null
        };
    } 

    componentDidMount() {
        window.scrollTo(0,0);
        const { id } = this.props.match.params;
        var { sales_token } = this.state;

        // basic auth for WP rest api calls
        const authRestApi = {
            method: 'GET',
            url: '/api/auth/sales'
        };

        axios.request(authRestApi).then((response) => {
            //console.log(response.data);
            sales_token = response.data.jwt_token ;

            //console.log({id});
            //var getVilla = axios.get('https://sales.rocks-estates.com/wp-json/wp/v2/opalestate_property?include[]=' + id );
            const getVilla = {
                method: 'GET',
                url: '/api/property/getProperty',
                params:{
                    id: id,
                    token: sales_token
                }
            };

            axios.request(getVilla).then((res) => {
                //console.log(res);
                this.setState({
                    property: res.data[0],
                    id: res.data[0].id,
                    villa_name: res.data[0].title,
                    villa_desc: res.data[0].content,
                    opalestate_ppt_areasize: res.data[0].opalestate_ppt_areasize,
                    opalestate_ppt_house_size: res.data[0].opalestate_ppt_house_size,
                    opalestate_ppt_renovation_year: res.data[0].opalestate_ppt_renovation_year,
                    opalestate_ppt_bedrooms: res.data[0].opalestate_ppt_bedrooms,
                    opalestate_ppt_bathrooms: res.data[0].opalestate_ppt_bathrooms,
                    opalestate_ppt_livingrooms: res.data[0].opalestate_ppt_livingrooms,
                    opalestate_ppt_kitchens: res.data[0].opalestate_ppt_kitchens,
                    opalestate_amenities: res.data[0].opalestate_amenities,
                    featured_media: res.data[0].featured_media,
                    opalestate_city: res.data[0].opalestate_city,
                    opalestate_ppt_map: res.data[0].opalestate_ppt_map,
                    opalestate_ppt_sku: res.data[0].opalestate_ppt_sku,
                    isLoaded: true
                });

                const featured_media  = res.data[0].featured_media;

                const getImageUrl = {
                    method: 'GET',
                    url: '/api/media/getImageUrl',
                    params:{
                        featured_media: featured_media,
                        token: sales_token,
                        website: "sales"
                    }
                };

                axios.request(getImageUrl).then((res_ft_image) => {
                    //console.log(res_ft_image);
                    this.setState({
                        imgUrl: res_ft_image.data.media_details.sizes.full.source_url
                    });
                });
                
                /*
                const getImagesUrl = {
                    method: 'GET',
                    url: '/api/property/getImagesUrl',
                    params:{
                        id: id,
                        token: sales_token
                    }
                };

                axios.request(getImagesUrl).then((res_images) => {
                    //console.log(res_images.data);
                    this.setState({
                        images: res_images.data
                    });
                });

                const getAmenities = {
                    method: 'GET',
                    url: '/api/property/getAmenities',
                    params:{
                        id: id,
                        token: sales_token
                    }
                };

                axios.request(getAmenities).then((res_amenities) => {
                    //console.log(res_amenities);
                    this.setState({
                        amenities: res_amenities.data
                    });
                });

                const getType = {
                    method: 'GET',
                    url: '/api/property/getType',
                    params:{
                        id: id,
                        token: sales_token
                    }
                };

                axios.request(getType).then((res_type) => {
                    //console.log(res_type);
                    this.setState({
                        opalestate_types: res_type.data[0].name
                    });
                });

                const getCategory = {
                    method: 'GET',
                    url: '/api/property/getCategory',
                    params:{
                        id: id,
                        token: sales_token
                    }
                };

                axios.request(getCategory).then((res_category) => {
                    //console.log(res_category);
                    this.setState({
                        property_category: res_category.data[0].name
                    });
                });
                
                const getLocation = {
                    method: 'GET',
                    url: '/api/property/getLocation',
                    params:{
                        id: id,
                        token: sales_token
                    }
                };

                axios.request(getLocation).then((res_location) => {
                    //console.log(res_location.data);
                    this.setState({
                        opalestate_location: res_location.data[0].name
                    });
                });

                const getState = {
                    method: 'GET',
                    url: '/api/property/getState',
                    params:{
                        id: id,
                        token: sales_token
                    }
                };

                axios.request(getState).then((res_state) => {
                    //console.log(res_state.data);
                    this.setState({
                        opalestate_state: res_state.data[0].name
                    });
                });

                const getCity = {
                    method: 'GET',
                    url: '/api/property/getCity',
                    params:{
                        id: id,
                        token: sales_token
                    }
                };

                axios.request(getCity).then((res_city) => {
                    //console.log(res_city.data);
                    this.setState({
                        opalestate_city: res_city.data[0].name
                    });
                }); */

                const getData = {
                    method: 'GET',
                    url: '/api/property/getData',
                    params:{
                        id: id,
                        token: sales_token
                    }
                };

                axios.request(getData).then((res_data) => {
                    //console.log(res_data);
                    if(res_data.data.city[0] !== undefined){
                        this.setState({
                            opalestate_city: res_data.data.city[0].name
                        });
                    }
                    if(res_data.data.amenities !== undefined){
                        this.setState({
                            amenities: res_data.data.amenities
                        });
                    }
                    if( res_data.data.location[0] !== undefined){
                        this.setState({
                            opalestate_location: res_data.data.location[0].name
                        });
                    }
                    if( res_data.data.category[0] !== undefined){
                        this.setState({
                            property_category: res_data.data.category[0].name
                        });
                    }
                    if( res_data.data.type[0] !== undefined){
                        this.setState({
                            opalestate_types: res_data.data.type[0].name
                        });
                    }
                    if( res_data.data.state[0] !== undefined){
                        this.setState({
                            opalestate_state: res_data.data.state[0].name
                        });
                    }
                    this.setState({
                        images: res_data.data.images,
                        isLoaded: true
                    });
                    
                });

                window.scrollTo(0,0);

                
                ///const getImageUrl = axios.get('https://sales.rocks-estates.com/wp-json/wp/v2/media/'+featured_media);
                //const getImagesUrl = axios.get('https://sales.rocks-estates.com/wp-json/wp/v2/media?parent=' + id + '&per_page=50');
                //const getAmenities = axios.get('https://sales.rocks-estates.com/wp-json/wp/v2/opalestate_amenities?post='+id);
                //const getCategory = axios.get(`https://sales.rocks-estates.com/wp-json/wp/v2/property_category?post=`+id);
                //const getType = axios.get(`https://sales.rocks-estates.com/wp-json/wp/v2/opalestate_types?post=`+id);
                //const getLocation = axios.get(`https://sales.rocks-estates.com/wp-json/wp/v2/opalestate_location?post=`+id);
                //const getState = axios.get(`https://sales.rocks-estates.com/wp-json/wp/v2/opalestate_state?post=`+id);
                //const getCity = axios.get(`https://sales.rocks-estates.com/wp-json/wp/v2/opalestate_city?post=`+id);     
                /*
                Promise.all([getImageUrl, getCity, getImagesUrl, getAmenities, getCategory, getType, getLocation, getState, getCity]).then(res => {
                    console.log(res);
                    this.setState({
                        imgUrl: res[0].data.media_details.sizes.full.source_url,
                        opalestate_city: res[1].data.name,
                        images: res[2].data,
                        amenities: res[3].data,
                        property_category: res[4].data[0].name,
                        opalestate_types: res[5].data[0].name,
                        opalestate_location: res[6].data[0].name,
                        opalestate_state: res[7].data[0].name,
                        isLoaded: true
                    });
                    window.scrollTo(0,0);
                });*/
            });

        });      

    }

    


    render() {
        const { property, villa_name, villa_desc, imgUrl, opalestate_ppt_map , 
            opalestate_ppt_sku, opalestate_types, property_category, opalestate_location, 
            opalestate_state, opalestate_city, opalestate_ppt_areasize, opalestate_ppt_house_size, 
            opalestate_ppt_renovation_year, opalestate_ppt_bedrooms, opalestate_ppt_bathrooms,
            opalestate_ppt_livingrooms, opalestate_ppt_kitchens  } = this.state;
        var { amenities, images } = this.state;
        const { id } = this.props.match.params;
        const location={};
        location.lat = opalestate_ppt_map.latitude;
        location.lng = opalestate_ppt_map.longitude;
        //console.log(location); 

        return (
            <div>
                <Helmet>
                    <title> Property {id} || Properties in Greece</title>
                    <meta name="keywords" content={id} />
                    <meta
                    name="description"
                    content="Details about property"
                    />
                </Helmet>
                <div className="villa">
                <div className="container-fluid p-0">
                    <div style={{
                        backgroundImage: `url(${imgUrl})`, backgroundSize: 'cover',
                        backgroundPosition: 'center'
                    }} className="h-100">
                        <Container className='p-5'>
                            <Row className='p-5'>
                                <Col className=''>
                                    <h1 className="text-light text-center text-uppercase pt-5 pb-5 visually-hidden">{villa_name.rendered}</h1>
                                    <h1 className="text-light text-center text-uppercase pt-5 pb-5">Property {opalestate_ppt_sku}</h1>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>
                <Container className="mt-5">
                    <Row>
                        <Col>
                            <BiHomeAlt/>
                            <div className="text-left" dangerouslySetInnerHTML={{ __html: villa_desc.rendered }}></div>
                            <div className="text-left"><strong><span>ID: {id}</span></strong></div>
                            <div className="text-left"><strong><span>SKU: {opalestate_ppt_sku}</span></strong></div>
                        </Col>
                    </Row>
                </Container>
                <Container className=" mt-5">
                    <Row>
                        <Col sm="12" md="6" className="text-left">
                            <h2>Amenities</h2>
                            {amenities.map(amenity =>
                                <div key={amenity.id}>
                                    <span>{amenity.name}</span>
                                </div>
                            )}
                        </Col>
                        <Col sm="12" md="6" className="text-left"> 
                            <h2>Type</h2>
                            {opalestate_types}
                            <h2>Category</h2>
                            {property_category}     
                        </Col>
                    </Row> 
                </Container>
                <Container className=" mt-5">
                    <Row>
                        <Col sm="12" md="6" className="text-left">
                            <h2>Property Details</h2>
                            <div><span>Area Size: {opalestate_ppt_areasize} &#13217;</span></div>
                            <div><span>House Size: {opalestate_ppt_house_size} &#13217;</span></div>
                            <div><span>Renovation Year: {opalestate_ppt_renovation_year}</span></div>
                            <h2>House Details</h2>
                            <div><span>Bedrooms: {opalestate_ppt_bedrooms} </span></div>
                            <div><span>Bathrooms: {opalestate_ppt_bathrooms} </span></div>
                            <div><span>Living Rooms: {opalestate_ppt_livingrooms}</span></div>
                            <div><span>Kitchen Rooms: {opalestate_ppt_kitchens}</span></div>
                        </Col>
                        <Col sm="12" md="6" className="text-left"> 
                            <h2>Location</h2>
                            <p>
                                <span>{opalestate_location}</span>
                                <span> &#62; </span>
                                <span>{opalestate_state}</span> 
                                <span> &#62; </span>
                                <span>{opalestate_city}</span>
                            </p>  
                            <LocationMap position={location} villa={property} />
                        </Col>
                    </Row> 
                </Container>
                <Container className="mt-5">
                    <Row>
                        <Col>
                            <h2>Image Gallery</h2>
                            {this.state.images.length > 0 ? 
                                 <Container>
                                 <Row className='thumbnail-gallery'>
                                     <Gallery>
                                         {images.map(image =>
                                             <Item original={image.source_url}
                                             thumbnail={image.source_url}
                                             width="1024"
                                             height="768"
                                             >
                                             {({ ref, open }) => (
                                                 <img ref={ref} onClick={open} src={image.source_url} alt={image.alt_text} />
                                             )}
                                             </Item>
                                         )}
                                     </Gallery>
                                 </Row>
                                 <Row>
                                     <VillaCarousel items={images}/>
                                 </Row>
                             </Container> 
                             : 
                             <div className="col-md-4 offset-md-6"></div> }
                        </Col>
                    </Row>
                </Container>
                
            </div>
          </div>
        );
    }
}

export default SellingVillaView;
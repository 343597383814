import React, { useState, useEffect } from 'react';
import { useParams , useLocation} from "react-router-dom";
import { Helmet } from "react-helmet";
import { Col , Row, Container , Progress, ProgressProps} from 'reactstrap';
import axios from 'axios';
import LocationsMap from '../Components/LocationsMap';
import L from 'leaflet';
import "leaflet/dist/leaflet.css";

const Cities = (props) => { 
    const [state, setState] = useState('');
    const [collapsed, setCollapsed] = useState(true);
    let [isLoaded , SetLoaded] = useState(false);
    const [lat, setLat] = useState(null);
    const [lng, setLng] = useState(null);
    const [location, setLocation] = useState([]);
    const [city_name, setCityName] = useState([]);
    const [city_desc, setCityDesc] = useState([]);
    const [featured_media, setFeaturedMedia] = useState(null);
    const [imgUrl, setImgUrl] = useState(null);
    const [getCity, setCity] = useState(null);
    const [listings, setListings] = useState([]);
    let [percentGetCompleted, setPercentage] = useState(35);

    const toggleNavbar = () => setCollapsed(!collapsed);

    var city_slug = Object.values(useParams())[0];

    useEffect(() => {

        //const getCity = axios.get('https://rocks-estates.com/wp-json/wp/v2/listing_cities?slug='+city_slug );
        /*
        const getCity = {
            method: 'GET',
            url: '/api/city/getCity',
            params: { city_slug: city_slug}
        };
        */

        axios.get('/api/city/getCity?city_slug='+city_slug, {
            onDownloadProgress: (progressEvent) => {
                setPercentage( Math.round((progressEvent.loaded * 100) / progressEvent.total));
                //console.log(progressEvent.lengthComputable)
                //console.log(percentGetCompleted);
            }
        })
        .then((res) => {
            //console.log(res);
            setCity(res.data);
            setLocation(res.data[0].acf.location);
            setLat(res.data[0].acf.location.lat);
            setLng(res.data[0].acf.location.lng);
            setCityName(res.data[0].name);
            setCityDesc(res.data[0].description);
            setPercentage(50);
            setFeaturedMedia(res.data[0].homey_taxonomy_img);
            
            //get featured image url by id
            const getImageUrl = {
                method: 'GET',
                url: '/api/media/getImageUrl',
                params:{
                    featured_media: res.data[0].homey_taxonomy_img
                }
            };
            axios(getImageUrl).then((res) => {
                //console.log(res);
                setImgUrl(res.data.source_url);
            }).catch(error => {
                if (error.response) {
                console.log(error.responderEnd);
                }
            });
        })
        .catch(error => {
            if (error.response) {
            console.log(error.responderEnd);
            }
        });

        if(featured_media != ""){
            
        }

        axios.get('/api/city/getProperties?city_slug='+city_slug, {
            onDownloadProgress: (progressEvent) => {
                setPercentage( Math.round((progressEvent.loaded * 100) / progressEvent.total) );
                //console.log(progressEvent.lengthComputable)
                //console.log(percentGetCompleted);                 
            }
        })
        .then((res) => {
            //console.log(res);
            setListings(res.data);
            SetLoaded(true);
            //console.log(this.state.listings);
        }).catch(error => {
            if (error.response) {
            console.log(error.responderEnd);
            }
        });
               
    }, [props, state] );

        
    const myIcon = L.divIcon({ className: 'my-div-icon',
        html: '<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 384 512" height="3em" width="3em" xmlns="http://www.w3.org/2000/svg"><path d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z"></path></svg>'
    });

    const meta_desc= "Luxury villas & properties at "+ {city_name} + " in Greece.";
    const meta_keywords = "luxury villas in " +{city_name} +", luxury properties in "+{city_name}+", luxury vacation at "+{city_name};

    var divStyle = {
        backgroundImage: 'url(' + imgUrl + ')',
        backgroundSize: 'cover',
        backgroundPosition: 'center'
    };

    return (
        <div className='city-view'>
            <Helmet>
                <title> City || Villas in Greece</title>
                <meta name="keywords" content={meta_keywords} />
                <meta
                name="description"
                content={meta_desc}
                />
            </Helmet>
            <div className="city">
            <div className="container-fluid p-0">
                {percentGetCompleted < 100 ? 
                    <Progress animated value={percentGetCompleted} /> : <Progress className='visually-hidden' animated value={percentGetCompleted} /> 
                    }
                <div style={divStyle} className="h-100">
                    <Container>
                        <Row>
                            <Col>
                                <h1 className="text-light text-center text-uppercase m-5 pt-5 pb-5">{city_name}</h1>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
            <Container className=" mt-5">
                <Row>
                    <Col sm="12" md="6"> 
                        <p className="d-none">{city_desc}</p>
                    </Col>
                </Row> 
            </Container>
            <Container className=" mt-5">
                <Row>
                    <Col > 
                        <LocationsMap listings={listings} position={location}  lat={lat} lng={lng}  />
                    </Col>
                </Row> 
            </Container>
            
        </div>
        </div>
    );
    

}

export default Cities;
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem , Progress} from 'reactstrap';
import { Link } from "react-router-dom";
import { AtomSpinner } from 'react-epic-spinners';

const MainNavBar = (props) => {
  const [collapsed, setCollapsed] = useState(true);
  const toggleNavbar = () => setCollapsed(!collapsed);
  let [cities, setCities] = useState({ cities: [] });
  const [isLoaded , SetLoaded] = useState(false);
  let [percentCompleted, SetPercentage] = useState(0);

  useEffect(() => {

    const getCities = {
      method: 'GET',
      url: '/api/city/getCities'
    }

    axios.get('/api/city/getCities', {
      onDownloadProgress: (progressEvent) => {
        SetPercentage( Math.round((progressEvent.loaded * 100) / progressEvent.total) );
        //console.log(progressEvent.lengthComputable)
        //console.log(percentCompleted);
      }
    }).then((response) => {
      setCities(response.data);
      SetLoaded(true);
    }).catch(error => {
      if (error.response) {
        console.log(error.responderEnd);
      }
    });

    /*
    const fetchData = async () => {
      const result = await axios(
        'https://rocks-estates.com/wp-json/wp/v2/listing_cities',
      );

      //console.log(result);
      setCities(result.data);
      SetLoaded(true);
    };
 
    fetchData();*/

  }, []);
  

  if (isLoaded){
    return (
      <div id="top">
        <Navbar color="dark" container dark fixed="top"  >
          <NavbarBrand href="/" className="mr-auto">
            <span>Villas in Greece</span>
          </NavbarBrand>
          <NavbarToggler onClick={toggleNavbar} className="mr-2" />
          <Collapse isOpen={!collapsed} navbar>
            <Nav navbar>
              <NavItem key="home" onClick={toggleNavbar}><Link className="nav-link" to={{pathname:`/`}}>Home</Link></NavItem>
              {cities.map((city, i)  =>  
                <NavItem key={i} onClick={toggleNavbar}><Link className="nav-link" to={{pathname: `/city/${city.slug}` }} 
                  key={city.id} >{city.name}</Link></NavItem>)}
              <NavItem key="rent" onClick={toggleNavbar}><Link className="nav-link" to={{pathname:`/rent/`}}>Rent a Property</Link></NavItem>
              <NavItem key="sell" onClick={toggleNavbar}><Link className="nav-link" to={{pathname:`/sell/`}}>Buy a Property</Link></NavItem>
            </Nav>
          </Collapse>                            
        </Navbar>
      </div>          
    );
  } else {
    return(
      <div>
        <Navbar color="dark" container dark fixed="top"  >
          <NavbarBrand href="/" className="mr-auto">
            <span>Villas in Greece</span>
          </NavbarBrand>
          <NavbarToggler onClick={toggleNavbar} className="mr-2" />
          <Collapse isOpen={!collapsed} navbar>
            <Nav navbar>
              <NavItem key="home" onClick={toggleNavbar}><Link className="nav-link" to={{pathname:`/`}}>Home</Link></NavItem>
              <NavItem key="citys" ><AtomSpinner color="primary" className="mx-auto" /></NavItem>
              <NavItem key="rent" onClick={toggleNavbar}><Link className="nav-link" to={{pathname:`/rent/`}}>Rent a Property</Link></NavItem>
              <NavItem key="sell" onClick={toggleNavbar}><Link className="nav-link" to={{pathname:`/sell/`}}>Buy a Property</Link></NavItem>
            </Nav>
          </Collapse> 
        </Navbar>
      </div>
    );
  };
}

export default MainNavBar;
import BackgroundHero from '../Components/BackgroundHero';
import Listings from './Rent_Listings';
import {Helmet} from 'react-helmet';


function Rent() {

  return (
    <div> 
      <Helmet>
        <title> Villas for rent in Greece</title>
        <meta name="keywords" 
          content="rent villas, rent villas in greece, rent properties in greece, luxury properties rent in greece, luxury villas in greece"/>
        <meta name="description" content="Find beautiful luxury villas in Greece for rent. Perfect places for your vacations."
        />
      </Helmet>
      <div className="App">
        <div className="main">
          <BackgroundHero key="HomepageImage"/>
          <h2>Villas to Rent</h2>
          <Listings/> 
        </div>
      </div>
    </div>
  );
}

export default Rent;


import React from 'react';

import classes from '../assets/css/BackgroundVideo.module.css';

const BackgroundHero = () => {

    return (
        <div className={classes.Container} style={{ backgroundImage: "url(/assets/img/Villa-Ammolite-pool.jpg)", backgroundSize: "cover", minHeight: "700px"}} >

            <div className={classes.Content} >
                <div className={classes.SubContent} >
                    <h1 >Villas in Greece</h1>
                    <p>Book your dreaming holidays</p>
                    <button type="button" className="btn btn-outline-light">View the Villas</button>
                </div>
            </div>
            
        </div>
    )
}

export default BackgroundHero
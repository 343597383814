import { useParams } from 'react-router-dom';
import axios from 'axios';
import * as ics from 'ics';

function ICALURL(props) {

    const params = useParams();
    //console.log(params);

    var disabledDates = [
        new Date(2021, 7, 1),
        new Date(2021, 7, 2),
    ];
    //console.log(disabledDates);

    var fantastic_id = null;
    if(props.fantastic_id != null) {
        fantastic_id = props.fantastic_id;
        //console.log(fantastic_id);
    }else{
        fantastic_id = params.id;
        //console.log(fantastic_id);
    }

    var calendar = "";

    const options = {
        method: 'GET',
        url: '/api/calendar',
        params: { listing_id: fantastic_id}
    }

    axios.request(options).then((response) => {
        //console.log(JSON.stringify(response.data));
        calendar= response.data.calendar;
        //console.log(calendar);

        var highlighted = [];
        var disabled_days = [];
        var events = [];
        var event = {};

        for(var i=0;i<calendar.length;i++) {
            var item = calendar[i];
            if(item.status === 'available') {
                highlighted.push(new Date(item.date));
            }
            else{
                disabled_days.push(new Date(item.date));
                //console.log( new Date(item.date).getFullYear() +" "+ String(parseInt(new Date(item.date).getMonth())+1) +" "+ new Date(item.date).getDate() );
                event = {
                    uid: "rs_reserved_day_"+i,
                    title: 'Unvailable',
                    summary: "Booked/Reserved",
                    start: [ new Date(item.date).getFullYear(), parseInt(new Date(item.date).getMonth())+1, new Date(item.date).getDate(), 0, 0 ] ,
                    end: [ new Date(item.date).getFullYear(), parseInt(new Date(item.date).getMonth())+1, new Date(item.date).getDate(), 23, 59 ] 
                };
                events.push(event);
                //console.log(event);
            }
        };

        event = {
            uid: "rs_reserved_day_"+ String(parseInt(disabledDates.length)+1),
            title: 'Unvailable',
            summary: "Booked/Reserved",
            start: [ parseInt(disabledDates[0].getFullYear()) +2 , parseInt(disabledDates[0].getMonth())+1, disabledDates[0].getDate(), 0, 0 ] ,
            end: [ parseInt(disabledDates[0].getFullYear()) +2 , parseInt(disabledDates[0].getMonth())+1, disabledDates[0].getDate(), 23, 59 ] 
        };
        events.push(event);
        //end of disabling for the forcoming years

        ics.createEvents(events, (error, value) => {
            if (error) {
                console.log(error)
            }
        });
        //console.log(ics.createEvents(events).value);

        const element = document.createElement("a");
        const file = new Blob([String(ics.createEvents(events).value)], {
            type: "text/plain"
        });
        element.href = URL.createObjectURL(file);
        //console.log(element.href);
        element.download = "calendar-"+fantastic_id+".ics";
        document.body.appendChild(element);
        element.click();

        
    });  


    return <h1>Success!!!</h1>;
}


export default ICALURL;
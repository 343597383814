import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import { Col , Row, Container, Button, Toast, ToastHeader, ToastBody} from 'reactstrap';
import { Link } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
import { BiHomeAlt , BiHeart, BiShareAlt, BiArrowBack, BiMap, BiDownload, 
    BiGroup, BiBed, BiBath, BiCheckCircle, BiHomeHeart, BiCalendar} from "react-icons/bi";
import { VscFilePdf } from "react-icons/vsc";
import axios from 'axios';
/* Images Modules */
import VillaCarousel from './VillaCarousel';
import 'photoswipe/dist/photoswipe.css';
import 'photoswipe/dist/default-skin/default-skin.css';
import { Gallery, Item } from 'react-photoswipe-gallery';
/* Map module */
import LocationMap from '../Components/LocationMap';
import "leaflet/dist/leaflet.css";
/* Calendar module */
import Calendar from '../Components/CalendarRangePicker';
import 'react-calendar/dist/Calendar.css';
import { AtomSpinner } from 'react-epic-spinners';
/* File Save module */
import * as JSZip from 'jszip';
import * as JSZipUtils from 'jszip-utils';
import { saveAs } from 'file-saver';
/* Slider Module */
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

class VillaView extends Component {
    

    constructor(props) {
        super(props);

        var today = new Date(),
        date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();

        this.villaRef = React.createRef();

        this.state = {
            villa: null,
            villa_name: [],
            villa_desc: [],
            fantastic_id: null,
            ical_url: null,
            featured_media: null,
            listing_cities: null,
            amenities: [],
            facilities: [],
            listing_guests: null,
            listing_bedrooms: null,
            listing_baths: null,
            listing_size: null,
            listing_amenities: [],
            listing_facilities: [],
            brochure: null,
            imgUrl: null,
            images: [],
            getCity: null,
            location: [],
            isLoaded: false,
            currentDate: date,
            modifiers: null,
            value: null, 
            onChange: null,
            disabledDates: null,
            disabled_days: null,
            dataNotYetFetched: false,
            apiResponse: "",
            calendar: [],
            isActive: false,
        };
        
    } 

    componentDidMount() {
        window.scrollTo(0,0);
        const id = this.props.match.params.id;
        var {calendar, modifiers}= this.state;
        //console.log(id);

        const getVillaData = {
            method: 'GET',
            url: '/api/villa',
            params: { id: id}
        }

        axios.request(getVillaData).then((response) => {
            //console.log(response.data);

            //Check for title in villa desc & replace it
            const title_unbranded = response.data[0].title.rendered;      
            var indexOfFirst = -2;
            //console.log(indexOfFirst);
            if( title_unbranded.indexOf("&")> -1){
                indexOfFirst = title_unbranded.indexOf("&");
            }else if(title_unbranded.indexOf("by")> -1){
                indexOfFirst = title_unbranded.indexOf("by");
            }else{
                indexOfFirst = title_unbranded.indexOf("-");
            }
            const desc_unbranded = response.data[0].content.rendered;
            var final = String(desc_unbranded).replace( title_unbranded.slice(0,indexOfFirst) , 'Villa ');
            final = final.slice( 0, final.indexOf("<div src="));

            this.setState({
                villa: response.data[0],
                villa_name: response.data[0].title,
                villa_desc: final,
                fantastic_id: response.data[0].acf.fantastic_id,
                ical_url: response.data[0].acf.ical_url,
                amenities: response.data[0].amenities,
                facilities: response.data[0].facilities,
                featured_media: response.data[0].featured_media,
                listing_cities: response.data[0].listing_cities,
                listing_guests: response.data[0].homey_guests,
                listing_bedrooms: response.data[0].homey_listing_bedrooms,
                listing_baths: response.data[0].homey_beds,
                listing_size: response.data[0].homey_listing_size,
                location: response.data[0].acf.location,
                brochure: response.data[0].acf.brochure,
                isLoaded: true
            });

            const { featured_media } = this.state;
            const { listing_cities } = this.state;     
            const getImageUrl = axios.get(process.env.REACT_APP_RENTAL_WEBSITE+`/wp-json/wp/v2/attachment/${featured_media}`);
            const getImagesUrl = axios.get(process.env.REACT_APP_RENTAL_WEBSITE+'/wp-json/wp/v2/attachment?parent=' + id + '&per_page=50');
            const getCity = axios.get(process.env.REACT_APP_RENTAL_WEBSITE+`/wp-json/wp/v2/listing_cities/${listing_cities}`);
            const getAmenities = axios.get(process.env.REACT_APP_RENTAL_WEBSITE+`/wp-json/wp/v2/amenities?post=`+ id);
            const getFacilities = axios.get(process.env.REACT_APP_RENTAL_WEBSITE+`/wp-json/wp/v2/facilities?post=`+ id);

            Promise.all([getImageUrl, getCity, getImagesUrl, getAmenities, getFacilities]).then(res => {
                //console.log(res);
                this.setState({
                    imgUrl: res[0].data.media_details.sizes.full.source_url,
                    listing_cities: res[1].data.name,
                    images: res[2].data,
                    listing_amenities: res[3].data,
                    listing_facilities: res[4].data,
                    isLoaded: true
                });
            });

            var fantastic_id = response.data[0].acf.fantastic_id; 
            //console.log(fantastic_id);    brochure_url              
            const ical_url = this.state.ical_url;
            var self = this;

            if(fantastic_id !== ""){
                //console.log("It's on FS");

                const options = {
                    method: 'GET',
                    url: '/api/calendar',
                    params: { listing_id: fantastic_id}
                }

                axios.request(options).then((response) => {
                    //console.log(JSON.stringify(response.data));
                    calendar= response.data.calendar;
                    //console.log(calendar);

                    var highlighted = [];
                    var disabled_days = [];
                        for(var i=0;i<calendar.length;i++) {
                            var item = calendar[i];
                            if(item.status === 'available') {
                                highlighted.push(new Date(item.date));
                            }
                            else{
                                disabled_days.push(new Date(item.date));
                            }
                        };

                    modifiers = {
                        disabled: disabled_days,
                        highlight: highlighted 
                    }
                    self.setState({
                        calendar: calendar,
                        modifiers: modifiers})
                        //console.log(modifiers);
                });
            }

            if((ical_url !== "")&&(ical_url !== undefined)){

                const options = {
                    method: 'GET',
                    url: '/api/ical',
                    params: { 
                        listing_id : id
                    }
                }

                //console.log("calling ical api serverless function");

                axios.request(options).then((response) => {
                    //console.log("it reached here");
                    //console.log(response);
                    calendar= response.data;
                    //console.log(calendar);

                    var highlighted = [];
                    var disabled_days = [];
                        for(var i=0;i<calendar.length;i++) {
                            var item = calendar[i];
                            if(item.status === 'available') {
                                highlighted.push(new Date(item.date));
                            }
                            else{
                                disabled_days.push(new Date(item.date));
                            }
                        };

                    modifiers = {
                        disabled: disabled_days,
                        highlight: highlighted 
                    }
                    self.setState({
                        calendar: calendar,
                        modifiers: modifiers
                    })
                    //console.log(modifiers);
                    window.scrollTo(0,0);
                    
                });

            }

        });         

    }

    componentDidUpdate() {
        
    }

    savePDF = () => {
        if(typeof(this.state.brochure) !== 'undefined' && this.state.brochure != null) {
            let brochure_url = this.state.brochure.url;
            const villa_id = this.state.villa.id;
            var zip = new JSZip();
            var zipFilename = "brochure-"+villa_id+".zip";

            var isOpen = this.state.isActive;
            this.setState({isActive: !this.state.isActive});

            JSZipUtils.getBinaryContent(brochure_url.replace(process.env.REACT_APP_RENTAL_WEBSITE,""), function (err, data) {
                if (err) {
                    console.log(err);
                    //throw err; // or handle the error
                }
                //console.log(data);
                const filetype = villa_id;
                zip.file(villa_id+".pdf", data, { binary: true });
                zip.generateAsync({ type: 'blob' }).then(function (content) {
                    console.log(content);
                    saveAs(content, zipFilename);
                });
            });
        }
    }

    saveFile = () => {
        const images_array = this.state.images;
        const villa_id = this.state.villa.id;
        var isOpen = this.state.isActive;
        //console.log(images_array); 
        console.log(isOpen); 

        var zip = new JSZip();
        var count = 0;
        var zipFilename = "Pictures.zip";

        this.setState({isActive: !this.state.isActive});

        images_array.forEach(function (url, i) {
            // loading a file and add it in a zip file
            //console.log(url.source_url.replace(process.env.REACT_APP_RENTAL_WEBSITE,""));
            JSZipUtils.getBinaryContent(url.source_url.replace(process.env.REACT_APP_RENTAL_WEBSITE,""), function (err, data) {
                if (err) {
                    console.log(err);
                    //throw err; // or handle the error
                }
                //console.log(data);
                const filetype = images_array[count].media_details.file.substring(images_array[count].media_details.file.indexOf('.'));
                zip.file(villa_id+"-"+count + filetype, data, { binary: true });
                count++;
                if (count === images_array.length) {    
                    zip.generateAsync({ type: 'blob' }).then(function (content) {
                        console.log(content);
                        saveAs(content, zipFilename);
                    });
                }
            });
        });
    }

    render() {
        const { villa_name, villa_desc, imgUrl, images , location ,
            listing_amenities, listing_facilities, listing_guests, listing_bedrooms,
            listing_baths, listing_size, modifiers, fantastic_id, calendar, isActive} = this.state;
        const id = this.props.match.params.id;

        //console.log(images);

        const settings = {
            dots: false,
            infinite: true,
            speed: 500,
            rows: 1,
            slidesToShow: 8,
            slidesToScroll: 1,
            slidesPerRow: 1,
            centerMode: true,
            variableWidth: true,
            adaptiveHeight: true,
            swipeToSlide: true,
            autoplay: true,
            autoplaySpeed: 2000,
            responsive: [
                {
                    breakpoint: 576,
                    settings: {
                        slidesPerRow: 1,
                        slidesToShow: 4,
                        rows: 1
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesPerRow: 1,
                        slidesToShow: 6,
                        rows: 1
                    }
                },
                {
                    breakpoint: 992,
                    settings: {
                        slidesPerRow: 1,
                        rows: 1
                    }
                }
            ]
          };
        //console.log(images);

        return (
            <div ref={this.villaRef} className={id}>
                <Helmet>
                    <title> Villa {id} || Villas in Greece</title>
                    <meta name="keywords" content={id} />
                    <meta
                    name="description"
                    content="Details about villa"
                    />
                    <meta name="viewport" content="width=device-width, initial-scale=1" />
                </Helmet>
                <div className="villa">
                <Container fluid className="p-0">
                    <div style={{
                        backgroundImage: `url(${imgUrl})`, backgroundSize: 'cover',
                        backgroundPosition: 'center'
                    }} className="h-100">
                        <Container className='p-5'>
                            <Row className='p-5'>
                                <Col>
                                    <h1 className="text-light text-center text-uppercase pt-5 pb-5 visually-hidden">{villa_name.rendered}</h1>
                                    <h1 className="text-light text-center text-uppercase pt-5 pb-5">Villa {id}</h1>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    {this.state.images.length > 0 ? 
                        <div>
                            <Row className="thumbnail-gallery">
                                <Slider {...settings} className="p-0">
                                            {images.map( (image ,index) =>
                                                <Item original={image.source_url}
                                                thumbnail={image.source_url} key={index}
                                                width="1024"
                                                height="768"
                                                class="img-thumb"
                                                >
                                                {({ ref, open }) => (
                                                    <img ref={ref} onClick={open} src={image.source_url} alt={image.alt_text}/>
                                                )}
                                                </Item>
                                            )}
                                </Slider>
                            </Row>
                            <Row className='d-none'>
                                <VillaCarousel items={images}/>
                            </Row>
                        </div>
                    : 
                    <div className="col-md-4 offset-md-6"></div> }
                </Container>
                <Container fluid className="p-0 dark">
                    <Container className="pt-0">
                        <Row>
                            <div className="col-md-6 col-sm-4 col-4 p-0 align-middle">
                                <div className='float-start'>
                                    <Link className = "mb-2" to = {
                                        {pathname: '/',
                                            state: {
                                                villa: null,
                                                villa_name: null,
                                                fantastic_id: null
                                            }
                                        } } >
                                        <Button className="mb-2" key="back-to-villas" outline> <BiArrowBack/> Back to villas </Button>
                                    </Link> 
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-8 col-8 p-0 align-middle">
                                <div className="float-end functional-buttons">
                                    <Button color="primary">Book</Button>
                                    <Button color="info" outline target="_blank" key="pdf-download-button" onClick={this.savePDF}><VscFilePdf/></Button>
                                    <Button color="info" outline><BiHeart/></Button>
                                    <HashLink className = "mb-2" to="#Location">
                                        <Button color="info" outline>
                                            <BiMap/>
                                        </Button>
                                    </HashLink>
                                    <Button color="info" outline><BiShareAlt/></Button>
                                </div>
                            </div>
                        </Row>
                    </Container>
                </Container>
                <Container className="mt-5">
                    <Row className="quick-details">
                        <Col>
                            <div className="circle-icons">
                                <span className="custom-icon"><BiGroup /></span>
                                <span className="text">Guests </span>
                                <span className="text">{listing_guests}</span>
                            </div>
                        </Col>
                        <Col>
                            <div className='circle-icons'>
                                <span className="custom-icon"><BiBed/></span>
                                <span className="text">Bedrooms </span>
                                <span className="text">{listing_bedrooms}</span>
                            </div>
                        </Col>
                        <Col>
                            <div className='circle-icons'>
                                <span className="custom-icon"><BiBath/></span>
                                <span className="text">Baths </span>
                                <span className="text">{listing_baths}</span>
                            </div>
                        </Col>
                        <Col>
                            <div className='circle-icons'>
                                <span className="custom-icon"><BiHomeHeart/></span>
                                <span className="text">House Size </span>
                                <span className="text">{listing_size}</span>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="12" md="6">
                            <h2 id="Description">Description</h2>
                            <BiHomeAlt/>
                            {this.state.isLoaded ? 
                                <div><div className="text-left" dangerouslySetInnerHTML={{ __html: villa_desc }}></div>
                                <div className="text-left"><span>ID: {id}</span></div></div> : 
                                <div className="col-md-4 offset-md-6"><AtomSpinner color="primary"/></div> }
                        </Col>
                        <Col sm="12" md="6">
                            <h2>Availability</h2>
                            <BiCalendar/>
                            <div className="text-center">
                                <Calendar modifiers={modifiers} fantastic_id={fantastic_id} calendar={calendar}/>
                            </div>  
                        </Col>
                    </Row>
                </Container>
                <Container className=" mt-5">
                    <Row>
                        <Col sm="12" md="6">
                            <h2 id="Amenities">Amenities</h2>
                            {listing_amenities.map(amenity =>
                                <div key={amenity.name} className={amenity.name} >
                                    <BiCheckCircle/>
                                    <span> {amenity.name}</span>
                                </div>
                            )}
                        </Col>
                        <Col sm="12" md="6"> 
                            <h2 id="Facilities">Facilites</h2>
                            {listing_facilities.map(facility =>
                                <div key={facility.name} className={facility.name}>
                                    <BiCheckCircle/> 
                                    <span> {facility.name}</span>
                                </div>
                            )}
                        </Col>
                    </Row> 
                </Container>
                <Container className=" mt-5">
                    <Row>
                        <Col></Col>
                        { this.state.isLoaded ? 
                            <Col sm="12" md="10"  zindex="0" > 
                                <h2 id="Location">Location</h2>
                                <LocationMap position={location} />
                            </Col>
                        : <Col></Col>
                        }
                        <Col></Col>
                    </Row> 
                </Container>
                <Container className="mt-5" >
                    <Row>
                        <Col className="p-0">
                            <h2 id="Gallery">Image Gallery</h2> 
                            <div>
                                <Button target="_blank" className="mb-2" key="image-gallery-button" onClick={this.saveFile}><BiDownload/> Download Images</Button>     
                            </div>
                            {this.state.images.length > 0 ? 
                                <Container>
                                    <Row className="thumbnail-gallery pb-5">
                                        <Gallery>
                                            <ul>
                                            {images.map( (image, index) =>
                                                <li key={image.id} >
                                                <Item key={index} original={image.source_url}
                                                thumbnail={image.source_url}
                                                width="1024"
                                                height="768"
                                                >
                                                {({ ref, open }) => (
                                                    <img ref={ref} onClick={open} src={image.source_url} alt={image.alt_text} />
                                                )}
                                                </Item></li>
                                            )}
                                            </ul>
                                        </Gallery>
                                    </Row>
                                    <Row className='d-none'>
                                        <VillaCarousel items={images}/>
                                    </Row>
                                </Container>
                            : 
                            <div className="col-md-4 offset-md-6"></div> }
                        </Col>
                    </Row>
                </Container>
                
            </div>
          </div>
        );
    }



}

export default VillaView;
import React, { useState, useEffect } from 'react';
import {Card, CardImg, CardText, CardBody, CardTitle, CardSubtitle, Button } from 'reactstrap';  
import { Link } from "react-router-dom";
import { MapContainer, TileLayer, Marker, Popup, useMap  } from 'react-leaflet';
import L from 'leaflet';
import "leaflet/dist/leaflet.css";
import '../assets/css/custom.css';

function LocationsMap(props){    
  
    var lat = '37.267496';
    var lng = '25.321899';
    var listings = [];

    if(props.lat != null) {
        lat = props.lat;
        lng = props.lng;
    }
    if(props.listings != null) {
        listings = props.listings;
    }    
    //console.log(lat); 
    //console.log(lng); 
    //console.log(listings); 
    

    const myIcon = L.divIcon({
        className: 'my-div-icon',
        iconSize: [41, 51], // size of the icon
        iconAnchor: [20, 51], // point of the icon which will correspond to marker's location
        popupAnchor: [0, -51],
        html: '<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 384 512" height="3em" width="3em" xmlns="http://www.w3.org/2000/svg"><path d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z"></path></svg>'
    }); 

    return (
        
        <div id="mapid">
            <MapContainer center={[ lat , lng  ]} zoom={12} scrollWheelZoom={false} tap={false}>
                <TileLayer
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />

                {listings.map(listing =>
                    <Marker key={listing.id} icon={myIcon} 
                        position={[listing.acf.location.lat || 37.267496  , listing.acf.location.lng || 25.321899 ]}>
                        <Popup>
                        <Card>
                            <CardImg top width="100%" src={listing._embedded['wp:featuredmedia'][0].media_details.sizes.thumbnail.source_url} alt={listing.title.rendered} />
                            <CardBody>
                                <CardTitle tag="h5">{listing.title.rendered} </CardTitle>
                                <CardSubtitle tag="h6" className="mb-2 text-muted"></CardSubtitle>
                                <CardText></CardText> 
                                <Link className="mb-2" to={{pathname: `/villa/${listing.id}`, state: { villa : listing.id, villa_name: listing.title.rendered} }} >
                                    <Button className="mb-2" key={listing.id} >View Villa</Button>
                                </Link>
                            </CardBody>
                        </Card>                         
                        </Popup>
                    </Marker>
                )}
                <MyComponent lat={lat} lng={lng}/>
            </MapContainer>
        </div>
    );

}

function MyComponent(props) {
    const map = useMap('mapid', {
        tap: false
    });
    
    var lat = '';
    var lng = '';

    lat = props.lat;
    lng = props.lng;

    if(true){
        map.panTo([lat , lng]);
        //console.log('map center:', map.getCenter());
    }
    else{

    }

    return null;

  }

export default LocationsMap;
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.scss';
import './assets/css/custom.css';
import MainNavBar from './Main_Components/MainNavBar';
import FooterNavBar from './Main_Components/FooterNavBar';
import VillaView from './Main_Components/VillaView';
import SellingVillaView from './Main_Components/SellingVillaView';
import Cities from './Main_Components/Cities';
import Rent from './Main_Components/Rent';
import Sell from './Main_Components/Sell';
import Home from './Main_Components/Home';
import ICalURL from './Components/ICalURL';

import {
  BrowserRouter,
  Switch,
  Route
} from "react-router-dom";

function App() {

  return (
    <div className="App">  
      <BrowserRouter basename="/" >
      <header>
        <MainNavBar key="MainMenu"/>
      </header>
      <div className="main mb-5">
        <Switch>
          <Route  path="/" exact > <Home /> </Route>
          <Route key='/city/:city_name' exact={true} path='/city/:city_name' render={props => <Cities />}/>
          <Route key='/villa/:id' exact={true} path='/villa/:id' component={VillaView} />
          <Route key='/villa/:id/ICalURL' exact={true} path='/villa/:id/ICalURL' component={ICalURL} />
          <Route key='/property/:id' exact={true} path='/property/:id' component={SellingVillaView} />
          <Route key='/rent/' exact={true} path='/rent' component={Rent} />
          <Route key='/sell/' exact={true} path='/sell' component={Sell} />
        </Switch>
      </div>
      <footer>
        <FooterNavBar key="FooterMenu"/>
      </footer> 
      </BrowserRouter>  
    </div>
  );
}

export default App;

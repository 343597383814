import React, { useState, useEffect } from 'react';
import { Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem, Container, Row, Col, ButtonGroup} from 'reactstrap';
import { Link } from "react-router-dom";
import { AtomSpinner } from 'react-epic-spinners';
import { BiHomeAlt , BiHeart, BiOutline, BiArrowBack, BiMap, BiArrowToTop, 
    BiGroup, BiBed, BiBath, BiCommand, BiHomeHeart, BiSearch} from "react-icons/bi";
import '.././Footer.scss';

const FooterNavBar = (props) => {
  
  const [isLoaded , SetLoaded] = useState(true); 

  useEffect(() => {
    

    

  }, []);
  
  const handleClick = event => {
    
  };

  

  if (isLoaded){
    return (  
      <Container fluid className="footer">
        <Row>
            <Navbar color="light" sticky="bottom" expand fixed="bottom" light  className='navbar fixed-bottom navbar-light w-100'>
              <div className=" d-flex flex-row justify-content-around w-100" id="tabbar">
                <NavbarToggler onClick={function noRefCheck(){}} />
                <Collapse navbar>
                  <Nav className="me-auto" navbar >
                    <NavItem>
                      <Link href="/" to={{pathname:`/`}}>
                        <BiCommand className="#icon-home" />
                      </Link>
                      <svg className="indicator" viewBox="0 0 68 72">
                        <defs>
                            <filter id="goo" x="-50%" width="200%" y="-50%" height="200%" colorInterpolationFilters="sRGB">
                                <feGaussianBlur in="SourceGraphic" stdDeviation="1" result="blur" />
                                <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 21 -7" result="cm" />
                            </filter>
                        </defs>
                        <g filter="url(#goo)">
                            <path d="M34 54C45.4078 54 48.3887 66.7534 68 72H0C19.6113 66.7534 22.5922 54 34 54Z" />
                            <circle cx="34" cy="66" r="4" />
                        </g>
                      </svg>
                    </NavItem>
                    <NavItem>
                      <Link href="#" to={{pathname:`#`}}>
                        <BiSearch className="#icon-search" />
                      </Link>
                      <svg className="indicator" viewBox="0 0 68 72">
                        <defs>
                            <filter id="goo" x="-50%" width="200%" y="-50%" height="200%" colorInterpolationFilters="sRGB">
                                <feGaussianBlur in="SourceGraphic" stdDeviation="1" result="blur" />
                                <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 21 -7" result="cm" />
                            </filter>
                        </defs>
                        <g filter="url(#goo)">
                            <path d="M34 54C45.4078 54 48.3887 66.7534 68 72H0C19.6113 66.7534 22.5922 54 34 54Z" />
                            <circle cx="34" cy="66" r="4" />
                        </g>
                      </svg>
                    </NavItem>
                    <NavItem>
                      <Link href="#" to={{pathname:`#`}}>
                        <BiHomeAlt className="#icon-chat" />
                      </Link>
                      <svg className="indicator" viewBox="0 0 68 72">
                        <defs>
                            <filter id="goo" x="-50%" width="200%" y="-50%" height="200%" colorInterpolationFilters="sRGB">
                                <feGaussianBlur in="SourceGraphic" stdDeviation="1" result="blur" />
                                <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 21 -7" result="cm" />
                            </filter>
                        </defs>
                        <g filter="url(#goo)">
                            <path d="M34 54C45.4078 54 48.3887 66.7534 68 72H0C19.6113 66.7534 22.5922 54 34 54Z" />
                            <circle cx="34" cy="66" r="4" />
                        </g>
                      </svg>
                    </NavItem>
                    <NavItem>
                      <Link href="#" to={{pathname:`#`}}>
                        <BiOutline className="#icon-chat" />
                      </Link>
                    </NavItem>
                    <NavItem>
                      <Link href="/#top" to={{pathname:`#`}}>
                        <BiArrowToTop className="#icon-top" />
                      </Link>
                    </NavItem>
                    
                  </Nav>
                </Collapse>
              </div>  
            </Navbar>
        </Row>  
      </Container> 
    );
  } else {
    return(
      <div>
      </div>
    );
  };
}

export default FooterNavBar;
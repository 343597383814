import React, { useState, useEffect, useCallback } from 'react';
import { Form , Input, FormGroup, Col, Label, Button} from 'reactstrap';
import axios from 'axios';
import ListingsItem from './ListingsItem';
import { AtomSpinner } from 'react-epic-spinners';
import { BiReset, BiBed, BiGroup, BiMinus} from "react-icons/bi";
import { GiIsland } from "react-icons/gi";
import { BsPlus } from "react-icons/bs";
import Select from "react-select";

function RentListings() {
    const [listings, setListings] = useState([]);
    const [isLoaded, setisLoaded] = useState(false);
    let [guest_number, setGuestNumber] = useState();
    let [min_guests_number, setMinGuests] = useState(1);
    let [max_guests_number, setMaxGuests] = useState();
    let [beds_number, setBedsNumber] = useState();
    let [max_beds_number, setMaxBeds] = useState();
    let [min_beds_number, setMinBeds] = useState();
    let [selected_locations, setSelectedLocations] = useState([]);
    let locations_ids = [];
    let [locations,setLocations] = useState([]);
    var [displayed_listings, setDisplayedListings] = useState([]);

    useEffect(() => {
        axios.get(process.env.REACT_APP_RENTAL_WEBSITE+'/wp-json/wp/v2/listings?per_page=100')
       .then((response) => {
            setListings(response.data);
            setDisplayedListings(response.data);
            console.log(response.data);

            const guest_capacity = response.data.map( listing => {
                if(listing.homey_guests.includes("+")){
                    return addition(Number(listing.homey_guests.slice(0,listing.homey_guests.indexOf("+"))) , Number(listing.homey_guests.slice(listing.homey_guests.indexOf("+"))))
                    /*const a= Number(listing.homey_guests.slice(0,listing.homey_guests.indexOf("+")));
                    const b= Number(listing.homey_guests.slice(listing.homey_guests.indexOf("+")));
                    return Number(a+b);*/
                }                    
                return Number(listing.homey_guests);
            });
            console.log(guest_capacity);
            //setMinGuests(Math.min(...guest_capacity));
            setGuestNumber(Math.min(...guest_capacity));
            setMaxGuests(Math.max(...guest_capacity));

            const beds_capacity = response.data.map( listing => {
                if(listing.homey_beds.includes("+")){
                    return addition(Number(listing.homey_beds.slice(0,listing.homey_beds.indexOf("+"))) 
                                , Number(listing.homey_beds.slice(listing.homey_beds.indexOf("+"))));
                    /*
                    const a= Number(listing.homey_beds.slice(0,listing.homey_beds.indexOf("+")));
                    const b= Number(listing.homey_beds.slice(listing.homey_beds.indexOf("+")));
                    return Number(a+b);*/
                }                    
                return Number(listing.homey_beds);
            });
            console.log(beds_capacity);
            setBedsNumber(Math.min(...beds_capacity));
            setMinBeds(Math.min(...beds_capacity));
            setMaxBeds(Math.max(...beds_capacity));

            locations_ids = [...new Set( response.data.map(listing => {    
                if(listing.listing_cities != null) {
                    selected_locations = locations_ids;
                    return Number(listing.listing_cities);
                }
                }
            ))];
            //console.log(locations_ids);

            axios.get(process.env.REACT_APP_RENTAL_WEBSITE+'/wp-json/wp/v2/listing_cities?per_page=100')
            .then((response) => {
                const save_locations = []
                response.data.forEach(location => {
                    save_locations.push({id: location.id, value: location.id, label: location.name});
                });
                setLocations(save_locations);
                setSelectedLocations(save_locations);
                console.log(locations);


                setisLoaded(true);
            })
            .catch(err => console.log(err));

        })
       .catch(err => console.log(err));     
    }, [setListings]);

    const addition = (a,b) => {
        return a+b;
    };

    const StringSeperationToInt = (text) => {
        if(text.includes("+")){
            return addition(Number(text.slice(0,text.indexOf("+"))) , Number(text.slice(text.indexOf("+"))))
            /*const a= Number(listing.homey_guests.slice(0,listing.homey_guests.indexOf("+")));
            const b= Number(listing.homey_guests.slice(listing.homey_guests.indexOf("+")));
            return Number(a+b);*/
        }
        return text; 
    };

    const changeGuestNumber = event => {
        let { value, min, max } = event.target;
        value = Math.max(Number(min), Math.min(Number(max), Number(value)));
        setGuestNumber(value);
        console.log("guests: "+value);
        console.log("beds: "+beds_number);
        console.log(listings.filter(listing => listing.homey_guests >= value));
        applyFilters(value,beds_number,selected_locations);
    };

    const changeBedsNumber = event => {
        let { value, min, max } = event.target;
        value = Math.max(Number(min), Math.min(Number(max), Number(value)));
        setBedsNumber(value);
        console.log("beds: "+value);
        console.log("guests: "+guest_number);
        console.log(listings.filter(listing => listing.homey_beds >= value));
        applyFilters(guest_number,value,selected_locations);
    };

    const changeLocation = event => {
        let { value } = event.target;
        var options = event.target;
        setSelectedLocations(value);
        console.log("selected location: "+value);
        console.log(options);
        console.log(listings.filter(listing => listing.listing_cities == value));
        applyFilters(guest_number,beds_number,value);
    };

    const handleChange =  (option) => {
        console.log(option);
        if(option.length < 1 ){
            option = locations;
        }
        setSelectedLocations(option);
        applyFilters(guest_number,beds_number,option);
    };

    const applyFilters = useCallback((guests,beds,selected_locations) => {
        if(guests === undefined){
            guests = max_guests_number;
        }
        if(beds === undefined){
            beds = max_beds_number;
        }
        if(selected_locations === undefined ){
            console.log("locations error");
        }
        var selection = [];
        selected_locations.forEach( location => {
            selection = selection.concat(listings.filter(listing => String(listing.listing_cities) == location.id ));
        });
        console.log(guests + " " + beds);
        console.log(selected_locations);
        setDisplayedListings(selection.filter(listing => (listing.homey_guests >= guests)&&(listing.homey_beds >= beds )));
        console.log(selection.filter(listing =>( StringSeperationToInt(listing.homey_guests) >= guests)&&(  StringSeperationToInt(listing.homey_beds) >= beds )));
        }, [guest_number, beds_number, selected_locations],
    );   

    const IncrementGuests = () => {
        if( guest_number < max_guests_number){
            setGuestNumber((guest_number) => Number(guest_number) + 1);
            applyFilters( Number(guest_number)+1,beds_number,selected_locations);
        }
    }

    const DecreaseGuests = () => {
        if( guest_number > min_guests_number){
            setGuestNumber((guest_number) =>  Number(guest_number) - 1);
            applyFilters(Number(guest_number)-1,beds_number,selected_locations);
        }
    }

    const IncrementBeds = () => {
        if( beds_number < max_beds_number){
            setBedsNumber((beds_number) => Number(beds_number) + 1);
            applyFilters( Number(guest_number),Number(beds_number)+1,selected_locations);
        }
    }

    const DecreaseBeds = () => {
        if( beds_number > min_beds_number){
            setBedsNumber((beds_number) =>  Number(beds_number) - 1);
            applyFilters(Number(guest_number),Number(beds_number)-1,selected_locations);
        }
    }

    return (
        <div className="mb-5 pb-5"> 
            {isLoaded ? 
                <div className="container">
                    <div className="row p-3">
                        <div className="filters clearfix ">
                            <Form className="" inline>
                                <FormGroup className="text-start " row>
                                    <Col className=" me-3 mb-3 d-flex align-items-center">
                                        <Label for="guest_number" className="float-start m-auto me-3">
                                            <span hidden>Guests </span>
                                            <BiGroup className="fs-2"/>
                                        </Label>
                                        <Col className="float-start d-flex ">
                                            <Button color="primary" outline size="lg" className="rounded-circle"  onClick={IncrementGuests}>
                                                <BsPlus />
                                            </Button>
                                            <input type="number" min={min_guests_number} 
                                                max={max_guests_number} value={guest_number} 
                                                placeholder={max_guests_number} onChange={changeGuestNumber}/>
                                            <Button color="primary" outline size="lg" className="rounded-circle"  onClick={DecreaseGuests}>
                                                <BiMinus/>
                                            </Button>
                                        </Col>
                                    </Col>
                                    <Col className="me-3 mb-3 d-flex align-items-center">  
                                        <Label for="beds_number" className="float-start m-auto me-3">
                                            <span hidden>Bedrooms </span>
                                            <BiBed className="fs-2"/>
                                        </Label>
                                        <Col className="float-start d-flex ">
                                            <Button color="primary" outline size="lg" className="rounded-circle"  onClick={IncrementBeds}>
                                                <BsPlus />
                                            </Button>
                                            <input type="number" min={min_beds_number} 
                                                max={max_beds_number} value={beds_number} 
                                                placeholder={max_beds_number} onChange={changeBedsNumber}/>
                                            <Button color="primary" outline size="lg" className="rounded-circle"  onClick={DecreaseBeds}>
                                                <BiMinus/>
                                            </Button>
                                        </Col>
                                    </Col>
                                    <Col className="me-3 d-flex align-items-center col-12 col-md-4">
                                        <Label for="islands" className="float-start m-auto me-3" >
                                            <span hidden>Island </span>
                                            <GiIsland className="fs-2"/>
                                        </Label>
                                        <Col  className="float-start">
                                            <Select defaultValue={locations} isMulti name="locations" options={locations}
                                                className="basic-multi-select" classNamePrefix="select" onChange={handleChange}
                                            />
                                        </Col>
                                    </Col> 
                                </FormGroup>
                            </Form>
                        </div>
                        
                    </div>
                    <div className="row pb-5">
                            {displayed_listings.length > 0 ? displayed_listings.map(listing =>
                                <ListingsItem key={listing.id} listing={listing}/>
                            )
                            :
                            <span>Opss... No results...Try with other criteria.</span>
                            }
                    </div>
                </div>
            : <div><AtomSpinner color="primary"/></div>}
        </div>
    );
}

export default RentListings;
import React , { useState, useEffect } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { Button, Dropdown, DropdownMenu, DropdownToggle, DropdownItem, Table,
     Container, Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, Input, InputGroup} from 'reactstrap';  
import * as ics from 'ics'; 
import { BiDownload , BiCopy} from "react-icons/bi";
import { FaRegMoneyBillAlt } from "react-icons/fa";
import {AiOutlineEyeInvisible, AiOutlineEye} from "react-icons/ai";


function CalendarRangePicker(props){

    var [value, setValue] = useState(new Date());
    var [start_date, setStartDate] = useState(new Date());
    var [end_date, setEndDate] = useState(new Date());
    var [checkout_date , setCheckoutDate] = useState(new Date());
    var [pricing , setPricing] = useState(0);
    var selected_dates = [];
    const [dropdownOpen, setOpen] = useState(false);
    var [isModalOpen,setisModalOpen] = useState(false); 
    var [isAgent,setisAgent] = useState(false); 
    var [agent_password, setAgentPassword] = useState("");

    var disabledDates = [
        new Date(2021, 7, 1),
        new Date(2021, 7, 2),
    ];
    //console.log(disabledDates);
    
    var modifiers = null;
    if(props.modifiers != null) {
        modifiers = props.modifiers;
        //console.log(modifiers);
        disabledDates = modifiers.disabled;
    } 

    var fantastic_id = null;
    if(props.fantastic_id != null) {
        fantastic_id = props.fantastic_id;
        //console.log(fantastic_id);
    }

    var calendar = null;
    if(props.calendar != null) {
        calendar = props.calendar;
        //console.log(calendar);
    }

    function onChange(date) {
        //console.log(date);
        //start_date = date[0];
        //end_date = date[1];  
        setStartDate(date[0]);
        setEndDate(date[1]);
        setValue(date);
        setCheckoutDate(new Date(date[1].getTime()+(3600 * 1000 * 24)));

        //Debug
        /*
        console.log("Start Date: "+start_date.toISOString().slice(0, 10));
        console.log("End Date: "+end_date.toISOString().slice(0, 10));
        console.log("Checkout Date: "+checkout_date.toISOString().slice(0, 10));
        */
        // Calculate Pricing
    }

    useEffect(() => {
        //
        selected_dates = calendar.filter(element => element.date < checkout_date.toISOString().slice(0, 10) &&  element.date > start_date.toISOString().slice(0, 10));
        setPricing(selected_dates.reduce((accumulator, object) => {
            return accumulator + object.price;
        }, 0));
    });
    
    const saveFile = () => {
        var events = [];
        if(disabledDates.length > 0){
            var event = {};
            for (let i = 0; i < disabledDates.length; i++) { 
                //console.log( disabledDates[i].getFullYear() +" "+ String(parseInt(disabledDates[i].getMonth())+1) +" "+ disabledDates[i].getDate() );
                event = {
                    uid: "rs_reserved_day_"+i,
                    title: 'Unvailable',
                    summary: "Booked/Reserved",
                    start: [ disabledDates[i].getFullYear(), parseInt(disabledDates[i].getMonth())+1, disabledDates[i].getDate(), 0, 0 ] ,
                    end: [ disabledDates[i].getFullYear(), parseInt(disabledDates[i].getMonth())+1, disabledDates[i].getDate(), 23, 59 ] 
                };
                events.push(event);
                //console.log(event);
            };
            //clearing event
            event={};
            //disable after one year
            event = {
                uid: "rs_reserved_day_"+ String(parseInt(disabledDates.length)+1),
                title: 'Unvailable',
                summary: "Booked/Reserved",
                start: [ parseInt(disabledDates[0].getFullYear()) +2 , parseInt(disabledDates[0].getMonth())+1, disabledDates[0].getDate(), 0, 0 ] ,
                end: [ parseInt(disabledDates[0].getFullYear()) +2 , parseInt(disabledDates[0].getMonth())+1, disabledDates[0].getDate(), 23, 59 ] 
            };
            events.push(event);
            //end of disabling for the forcoming years
        }
        //console.log(events);

        ics.createEvents(events, (error, value) => {
            if (error) {
                console.log(error)
            }
        });
        //console.log(ics.createEvents(events).value);

        const element = document.createElement("a");
        const file = new Blob([String(ics.createEvents(events).value)], {
          type: "text/plain"
        });
        element.href = URL.createObjectURL(file);
        console.log(element.href);
        element.download = "calendar-"+fantastic_id+".ics";
        document.body.appendChild(element);
        element.click();   

    }

    function CopyToClipboard() {
        var getUrl = window.location;
        var baseUrl = getUrl.protocol + "//" + getUrl.host + "/villa/" + fantastic_id +"/ICalURL";
         /* Copy the text inside the text field */
        navigator.clipboard.writeText(baseUrl);
    }

    function toggle() {
        setisModalOpen(!isModalOpen);
    }

    const handlePassKeyDown = (event) => {
        if (event.key === 'Enter') {
            authenticateAgent();
        }
    }

    function authenticateAgent() {
        console.log("checking");
        if( agent_password ="rocksestates"){
            setisAgent(true);
            setAgentPassword(null);
            toggle();
        }
    }

    function hidePricing() {
        setisAgent(false);
    }

    const handlePassChange = (event) => {
        setAgentPassword(event.target.value);
    }

    return ( 
        <div>
            <Calendar
                // Make calendar not viewable for previous months
                minDate={new Date()}
                // Whether to show two months 
                showDoubleView = {false}
                ActiveStartDate = {new Date()} 
                returnValue={"range"}
                // settings for the calendar
                onChange={onChange} 
                // value={value} set no value so 1st render no selected day
                selectRange={true} 
                locale="en-US"
                autofocus={false}
                // disabled dates. Got data from channel manager
                tileDisabled={({date, view}) =>
                (view === 'month') && // Block day tiles only
                disabledDates.some(disabledDate =>
                date.getFullYear() === disabledDate.getFullYear() &&
                date.getMonth() === disabledDate.getMonth() &&
                date.getDate() === disabledDate.getDate()
                )} 
            />
            <Container >
                <Row>
                    <Col></Col>
                    <Col xs="10">
                        {String(start_date).length > 0 ? 
                            <Table responsive size="sm" bordered>
                                <tbody>
                                    <tr>
                                        <th>
                                            Check-in
                                        </th>
                                        <th>
                                            {String(start_date).slice(0, 15)}
                                        </th>
                                    </tr>
                                    <tr>
                                        <th>
                                            Check-out
                                        </th>
                                        <th>
                                            {String(checkout_date).slice(0, 15)}
                                        </th>
                                    </tr>
                                    {String(fantastic_id).length > 0 ? 
                                        <tr>
                                            <th>
                                                Price
                                            </th>
                                            <th>
                                                {isAgent ?
                                                <span>
                                                    {pricing.toLocaleString('el-GR', { style: 'currency', currency: 'EUR' })}
                                                    <Button className="float-end" key="hide-pricing" size="sm" outline onClick={hidePricing}><AiOutlineEyeInvisible/></Button>
                                                </span>
                                                :
                                                <Button key="show-pricing" outline onClick={toggle} size="sm"> <AiOutlineEye/> Show Price </Button>
                                                }
                                            </th>
                                        </tr>
                                        :
                                        <tr>
                                            <th>Price</th>
                                            <th>Upon request</th>
                                        </tr>
                                    }
                                </tbody>
                            </Table>
                            :
                            <div></div>
                        }

                        <Modal isOpen={isModalOpen} toggle={toggle}>
                            <ModalHeader toggle={toggle}> Display Pricing </ModalHeader>
                            <ModalBody>
                            Type the password to display pricing.
                            <InputGroup>
                                <Input refs="agent_password" id="agent_password" name="agent_password" type="password" 
                                placeholder="password" value={agent_password} onChange={handlePassChange} onKeyDown={handlePassKeyDown}/>
                                <Button onClick={authenticateAgent}>
                                Authenticate
                                </Button>
                            </InputGroup>
                            </ModalBody>
                            <ModalFooter>
                            <Button onClick={toggle}>
                                Close
                            </Button>
                            </ModalFooter>
                        </Modal>
                    </Col>
                    <Col></Col>
                </Row>
                {String(fantastic_id).length > 0 ? 
                    <Row> 
                        <Dropdown size="sm" toggle={() => { setOpen(!dropdownOpen) }} isOpen={dropdownOpen}>
                            <DropdownToggle caret>
                                Quick Actions
                            </DropdownToggle>
                            <DropdownMenu  end >
                                <DropdownItem>
                                    <span outline target="_blank" className="mb-2" key="calendar-ics" onClick={saveFile}><BiDownload/> Download iCal</span>     
                                </DropdownItem>
                                <DropdownItem>
                                    <span outline onClick={CopyToClipboard} className='primary'><BiCopy/> Copy Calendar Link</span>
                                </DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                    </Row>
                    :
                    <p></p>
                }
            </Container>
        </div>
    );
}


export default CalendarRangePicker;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import {
    Card, CardImg, CardText, CardBody,
    CardTitle, CardSubtitle, Button
  } from 'reactstrap';  
import {
    Link
  } from "react-router-dom";
import { AtomSpinner } from 'react-epic-spinners';

export class PropertiesItem extends Component {
   state = {
       imgUrl: '',
       opalestate_city: '',
       property: '',
       opalestate_ppt_sku: null,
       isLoaded: false
   }

   static propTypes = {
       property: PropTypes.object.isRequired
   }

   componentDidMount () {
        //const {featured_media} = this.props.property;
        //const {opalestate_city} = this.props.property;
        //const {id} = this.props.property;
        const property = this.props.property;
        const id = property["id"];
        //console.log(property);

        //console.log(images);
        const featured_media = property["featured_media"];
        const gallery = property["opalestate_ppt_gallery"];
        const opalestate_city = property["opalestate_city"];
        const images =  property["opalestate_images"];
        const amenities = property["opelestate_amenities"];
        const property_category = property["opalestate_category"];
        const opalestate_types = property["opalestate_type"];
        const opalestate_location = property["opalestate_location"];
        const opalestate_state = property["opalestate_state"];
        const opalestate_ppt_sku = property["opalestate_ppt_sku"]; 
        const opalestate_ppt_map_address = property["opalestate_ppt_map_address"];
        const opalestate_ppt_map_latitude = property["opalestate_ppt_map_latitude"];
        const opalestate_ppt_map_longitude = property["opalestate_ppt_map_longitude"];

        //if no ft image get the first of gallery array
        let imgUrl = gallery[featured_media];
        const keys = Object.keys(gallery);
        if(imgUrl===undefined){
            imgUrl = gallery[keys[0]];
        }
        //console.log(imgUrl);

        this.setState({
            opalestate_city: opalestate_city,
            images: images,
            imgUrl: imgUrl,
            amenities: amenities,
            property_category: property_category,
            opalestate_types: opalestate_types,
            opalestate_location: opalestate_location,
            opalestate_state: opalestate_state,
            opalestate_ppt_sku: opalestate_ppt_sku,
            opalestate_ppt_map_address: opalestate_ppt_map_address,
            opalestate_ppt_map_latitude: opalestate_ppt_map_latitude,
            opalestate_ppt_map_longitude: opalestate_ppt_map_longitude,
            isLoaded: true
        });

        const authRestApi = {
            method: 'GET',
            url: '/api/auth/sales'
        };

        axios.request(authRestApi).then((response) => {
            //console.log(response.data);
            const sales_token = response.data.jwt_token; 

            const getCity = {
                method: 'GET',
                url: '/api/city/getCity',
                params:{
                    id: id,
                    token: sales_token,
                    website: "sales"
                }
            };
    
            axios.request(getCity).then((res_city) => {
                //console.log(res_city);
                this.setState({
                    opalestate_city: res_city.data[0].name
                });
                //console.log(res_city.data[0].name);
            }).catch((error) => {
                console.error(error);
            });
        }).catch((error) => {
            console.error(error);
        });

       /*
       const authRestApi = {
            method: 'GET',
            url: '/api/auth/sales'
        };

        axios.request(authRestApi).then((response) => {
            //console.log(response.data);
            const sales_token = response.data.jwt_token; 

            const getData = {
                method: 'GET',
                url: '/api/property/getData',
                params:{
                    id: id,
                    token: sales_token
                }
            };

            axios.request(getData).then((res_data) => {
                //console.log(res_data);

                this.setState({
                    opalestate_city: res_data.data.city[0].name,
                    images: res_data.data.images,
                    amenities: res_data.data.amenities,
                    property_category: res_data.data.category[0].name,
                    opalestate_types: res_data.data.type[0].name,
                    opalestate_location: res_data.data.location[0].name,
                    opalestate_state: res_data.data.state[0].name,
                    isLoaded: true
                });
            });

            const getProperty = {
                method: 'GET',
                url: '/api/property/getProperty',
                params:{
                    id: id,
                    token: sales_token
                }
            };
    
            axios.request(getProperty).then((res_property) => {
                //console.log(res_property.data);
                this.setState({
                    opalestate_ppt_sku: res_property.data[0].opalestate_ppt_sku,
                    isLoaded: true
                });
                //console.log(res_property.data[0].opalestate_ppt_sku);
            });
        });  

            /*
    
            const getCity = {
                method: 'GET',
                url: '/api/property/getCity',
                params:{
                    id: id,
                    token: sales_token
                }
            };
    
            axios.request(getCity).then((res_city) => {
                //console.log(res_city);
                this.setState({
                    opalestate_city: res_city.data[0].name
                });
                //console.log(res_city.data[0].name);
            });

            const getProperty = {
                method: 'GET',
                url: '/api/property/getProperty',
                params:{
                    id: id,
                    token: sales_token
                }
            };
    
            axios.request(getProperty).then((res_property) => {
                //console.log(res_property.data);
                this.setState({
                    opalestate_ppt_sku: res_property.data[0].opalestate_ppt_sku,
                    isLoaded: true
                });
                //console.log(res_property.data[0].opalestate_ppt_sku);
            });
        });       

        /*
       const getImageUrl = axios.get(`https://sales.rocks-estates.com/wp-json/wp/v2/media/${featured_media}`);
       const getCity = axios.get(`https://sales.rocks-estates.com/wp-json/wp/v2/opalestate_city/${opalestate_city}`);
       const getVilla = axios.get('https://sales.rocks-estates.com/wp-json/wp/v2/opalestate_property?include[]=' + id);

      
       Promise.all([getImageUrl, getCity, getVilla]).then(res => {
           //console.log(res);
           this.setState({
               imgUrl: res[0].data.media_details.sizes.full.source_url,
               opalestate_city: res[1].data.name,
               opalestate_ppt_sku: res[2].data[0].opalestate_ppt_sku,
               isLoaded: true
           });
           //console.log(opalestate_city);
       });*/
    }
 
   render() {
       const { title, content, id, opalestate_ppt_map_address} = this.props.property;
       const { imgUrl, opalestate_city, opalestate_ppt_sku, isLoaded} = this.state;
       //console.log(id);
       const cleanText = content.rendered.replace(/<\/?[^>]+(>|$)/g, "");
       if(isLoaded){
        return (
            <div className="col-lg-4 float-left mb-5" > 
                 <Card>
                     <CardImg top width="100%" src={imgUrl} alt={title.rendered} />
                     <CardBody>
                         <CardTitle className="visually-hidden" tag="h5">{title.rendered}</CardTitle>
                         <CardTitle tag="h5">Property {id}</CardTitle>
                         <CardSubtitle tag="h6" className="mb-2 text-muted">{isNaN(opalestate_city)? <span>{opalestate_city}</span> : <span>{opalestate_ppt_map_address}</span>}</CardSubtitle>
                         <CardText className="visually-hidden">{cleanText}</CardText> 
                         <Link className="mb-2" to={{pathname: `/property/${id}`, state: { villa : id, villa_name: title.rendered} }} >
                             <Button className="mb-2" key={id} >View Property</Button>
                         </Link>
                         <CardSubtitle tag="h6" className="mb-2 text-muted text-align-right">SKU: {opalestate_ppt_sku}</CardSubtitle>
                     </CardBody>
                 </Card> 
            </div>
        )

       }else{
           return(
            <div className="col-lg-4 float-left mb-5" > 
            <Card>
                <CardBody>
                <AtomSpinner color="primary"/>
                </CardBody>
            </Card> 
       </div>
           )
       }
       
   }
}
export default PropertiesItem
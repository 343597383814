import BackgroundHero from '../Components/BackgroundHero';
import Properties from './Properties';
import {Helmet} from 'react-helmet';


function Sell() {

  return (
    <div> 
      <Helmet>
        <title> Villas for sale in Greece</title>
        <meta name="keywords" 
          content="buy villas, buy villas in greece, buy properties in greece, luxury properties in greece, luxury villas in greece"/>
        <meta name="description" content="Find beautiful luxury villas in Greece for sale. Perfect places for your vacations."
        />
      </Helmet>
      <div className="App">
        <div className="main">
          <BackgroundHero key="HomepageImage"/>
          <h2>Villas to Buy</h2>
          <Properties/> 
        </div>
      </div>
    </div>
  );
}

export default Sell;

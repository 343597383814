import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ListingsItem from './ListingsItem';
import { AtomSpinner } from 'react-epic-spinners';

function Listings() {
    const [listings, setListings] = useState([]);
    const [isLoaded, setisLoaded] = useState(false);

    useEffect(() => {
        axios.get(process.env.REACT_APP_RENTAL_WEBSITE+'/wp-json/wp/v2/listings?per_page=100')
       .then((response) => {
            setisLoaded(true);
            setListings(response.data);
        })
       .catch(err => console.log(err))     
    }, [setListings, setisLoaded] );

    return (
        <div> 
            {isLoaded ? 
                <div className="container">
                    <div className="row">
                            {listings.map(listing =>
                                <ListingsItem key={listing.id} listing={listing}/>
                            )}
                    </div>
                </div>
            : <div><AtomSpinner color="primary"/></div>}
        </div>
    );
}

export default Listings;
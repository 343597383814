import React, { Component } from 'react'
import axios from 'axios';
import PropertiesItem from './PropertiesItem';
import { AtomSpinner } from 'react-epic-spinners';
import { Button } from 'reactstrap';

export class Properties extends Component {
    constructor(props) {
        super(props)
        this.myRef = React.createRef()  
    }

    state = {
        properties: [],
        page: 1,
        listing: 1,
        isLoaded: false,
        isLoading: false,
        disable: false
    }

    componentDidMount () {

        const authRestApi = {
            method: 'GET',
            url: '/api/auth/sales'
        };

        axios.request(authRestApi).then((response) => {
            //console.log(response.data);
            const sales_token = response.data.jwt_token;
            const page = this.state.page;
            //console.log(page);

            const getProperties = {
                method: 'GET',
                url: '/api/property/getProperties',
                params:{
                    token: sales_token,
                    page: page
                }
            };

            axios.request(getProperties).then((res_properties) => {
                //console.log(res_properties.data);
                const properties = this.state.properties;
                const properties_array = properties.concat(res_properties.data); 
                this.setState({
                    properties: properties_array,
                    isLoading: true
                });
                //console.log(this.state.properties);
            }).catch(err => console.log(err))
            .finally(() => {
                this.setState({ 
                    isLoaded: true,
                    isLoading: false
                });
            });;
        }).catch((error) => {
            console.error(error);
        });
    }; 

    loadMore = (e) => {
        this.setState({
            page: this.state.page+1,
            isLoading: true,
            disabled: true
        });
        //console.log(this.state.page);
    };

    componentDidUpdate(prevProps, prevState) {
        if (prevState.page !== this.state.page) {
            // execute the code to load more
            const authRestApi = {
            method: 'GET',
            url: '/api/auth/sales'
        };

        axios.request(authRestApi).then((response) => {
            //console.log(response.data);
            const sales_token = response.data.jwt_token;
            const page = this.state.page;
            //console.log(page);

            const getProperties = {
                method: 'GET',
                url: '/api/property/getProperties',
                params:{
                    token: sales_token,
                    page: page
                }
            };

            axios.request(getProperties).then((res_properties) => {
                //console.log(res_properties);
                if(res_properties.data.name !== 'Error'){
                    //console.log(res_properties.data);
                    const properties = this.state.properties;
                    const properties_array = properties.concat(res_properties.data); 
                    this.setState({
                        properties: properties_array,
                        disabled: false,
                    });
                    //console.log(this.state.properties);
                    
                    // optimize coding
                    if(res_properties.data.length < 6){
                        console.log("No more properties than 6");
                        this.setState({
                            disable: true
                        });
                    }
                }
                else{
                    console.log("No more properties to show.");
                    this.setState({
                        disable: true
                    });
                }
                }).catch(err => {
                    console.log(err);
                })
                .finally(() => {
                    this.setState({ 
                        isLoaded: true,
                        isLoading: false,
                        disbaled: true
                    });
                });;
            }).catch((error) => {
                console.error(error);
            });
        }
    }

   
   render() {
        const {properties, isLoaded , isLoading, disable} = this.state;

        if(isLoaded){
            return (
                <div className="container">
                    <div className="row">
                        {properties.map((property, index) =>
                            <PropertiesItem key={property.id} ref={index} property={property}/>
                        )}
                    </div>
                    <div className="row">
                        <Button onClick={this.loadMore} className="btn-grad mb-2" id="button-load-more" disabled={disable || isLoading}>
                            {isLoading ? <span><AtomSpinner size="25" color="primary" 
                                style={{display:"inline-block",paddingRight:"5px",verticalAlign:"middle"}}/>Loading</span> : 'Load More' }
                        </Button>
                    </div>
               </div>
            );
        }else{
            return (
                <div className="container">
                    <div className="row">
                        <div><AtomSpinner color="primary"/></div>
                    </div>
               </div>
            );
        }
        
   }
}
export default Properties